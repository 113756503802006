'use client';

// import { Button } from '@/components/button';
import { NofixContentLayout } from '@/components/complete/contentLayout';
import { LoadingButton } from '@/components/custom/util';
import React, { useEffect, useMemo, useState } from 'react';
import { useAccount, useReadContract, useWaitForTransactionReceipt, useWriteContract } from 'wagmi';
// import {
//   faucetContractConfig,
//   tokenContractConfig,
// } from '@/config/contract-config';
import { formatEther, parseEther } from 'viem';
import { Heading1, Heading2 } from '@/components/heading';
import { SectionFormText, TextDescription } from '@/components/text';
import { ArrowUpRightIcon } from '@heroicons/react/24/outline';
export function HomeContentLayout({
  href,
  title,
  children
}: React.PropsWithChildren<{
  href?: string;
  title?: string;
}>) {
  const {
    address,
    isConnected
  } = useAccount();
  // const [hydrated, setHydrated] = useState(false);
  // const [text, setText] = useState('Copy');
  // const [icon, setIcon] = useState(false);
  // const [truncatAddress, setTruncatAddress] = useState<string>('');
  // const [isLoading, setIsLoading] = useState(false);

  // const { data: krnlBalance } = useReadContract({
  //   ...tokenContractConfig,
  //   functionName: 'balanceOf',
  //   args: [address as `0x${string}`],
  // });

  // const { writeContract: writeFaucetContract } = useWriteContract();

  // const krnlBalanceinString = useMemo(() => {
  //   const balanceInEther = formatEther(krnlBalance || parseEther('0'));
  //   return parseFloat(balanceInEther).toFixed(4);
  // }, [krnlBalance]);

  // useEffect(() => {
  //   setHydrated(true);
  // }, []);

  // const requestKRNL = async () => {
  //   setIsLoading(true);
  //   await writeFaucetContract(
  //     {
  //       ...faucetContractConfig,
  //       functionName: 'requestTokens',
  //       args: [],
  //     },
  //     {
  //       onError: () => {
  //         setIsLoading(false);
  //       },
  //       onSuccess: () => {
  //         setIsLoading(false);
  //       },
  //     }
  //   );
  // };

  // useEffect(() => {
  //   if (address) {
  //     setTruncatAddress(`${address.slice(0, 6)}...${address.slice(-4)}`);
  //   }
  // }, [address]);

  return <>
      <NofixContentLayout bg="bg-shark-950 hover:bg-shark-900 cursor-pointer" bgVector="bg-vector-gray" maxwidth="max-w-screen-2xl" paddingbottom="pb-0" border="border-2 border-white/10" href={href} data-sentry-element="NofixContentLayout" data-sentry-source-file="home-layout.tsx">
        {isConnected ? <div className="flex w-full items-center justify-between space-x-4 text-white">
            <div className="space-y-2">
              <SectionFormText>Documentation</SectionFormText>
              <Heading1>{title}</Heading1>
            </div>
            <div>
              <ArrowUpRightIcon className="h-6 w-6 fill-white" />
            </div>
          </div> : <div className="w-full">
            <Heading1>Home</Heading1>
            <TextDescription>
              Connect your wallet to get started
            </TextDescription>
          </div>}
      </NofixContentLayout>
      <div className="mx-auto w-full max-w-screen-2xl p-8">{children}</div>
    </>;
}