import { fromHex } from 'viem';
import {
  ContractResultItem,
  DappResultItem,
  KernelDataArray,
  KernelMetadata,
  KernelResultItem,
} from './model';

const fetchGeneralData = async (metadataCid: `0x${string}`) => {
  try {
    const cid = fromHex(metadataCid, {
      size: 64,
      to: 'string',
    });

    const response = await fetch('/api/files/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ cid }),
    });

    if (response.ok) {
      const resultData = await response.json();
      // Ensure you're parsing valid JSON data

      return resultData as KernelMetadata;
    }
  } catch (error) {
    console.error('Error fetching general data:', error);
    return null;
  }
};

export function convertToKernels(data: KernelDataArray): KernelResultItem[] {
  if (!Array.isArray(data)) {
    console.error('Data is not an array:', data);
    return [];
  }

  return data.flatMap((item) => {
    return item
      .filter((kernel) => kernel.status === 'success')
      .flatMap((kernel) => kernel.result);
  });
}

export function kernelMiddleWare(
  data: KernelResultItem[] | undefined
): KernelResultItem[] {
  if (data === undefined) {
    return [];
  }
  return data;
}

export function kernelWithMetadataMiddleWare(
  data: KernelResultItem[] | undefined
): KernelResultItem[] {
  if (data === undefined) {
    return [];
  }

  return data;
}

export function dappMiddleWare(
  data: DappResultItem[] | undefined
): DappResultItem[] {
  if (data === undefined) {
    return [];
  }
  return data;
}

export function contractMiddleWare(
  data: ContractResultItem[] | undefined
): ContractResultItem[] {
  if (data === undefined) {
    return [];
  }
  return data;
}
