export type KernelType = 'onchain' | 'offchain';

export enum KernelTypeEnum {
  offchain = 1,
  onchain = 2,
}

export const resolveKernelTypeToInt = (type: KernelType): number => {
  return KernelTypeEnum[type];
};

export const resolveIntToKernelType = (value: number): KernelType => {
  switch (value) {
    case KernelTypeEnum.onchain:
      return 'onchain';
    case KernelTypeEnum.offchain:
      return 'offchain';
    default:
      throw new Error('Unknown integer value for KernelType');
  }
};

export const resolverTypeToDisplayName = (value: number): string => {
  switch (value) {
    case KernelTypeEnum.onchain:
      return 'Onchain (View Adapter)';
    case KernelTypeEnum.offchain:
      return 'Offchain';
    default:
      return 'Unknown';
  }
};

export const resolveStringToKernelType = (value: string): KernelTypeEnum => {
  switch (value) {
    case 'onchain':
      return KernelTypeEnum.onchain;
    case 'offchain':
      return KernelTypeEnum.offchain;
    default:
      throw new Error('Unknown string value for KernelType');
  }
};
