export function ContentLayout({
  bg,
  bgVector,
  paddingbottom,
  children,
  explore
}: React.PropsWithChildren<{
  bg: string;
  bgVector: string;
  paddingbottom?: string;
  explore?: string;
}>) {
  return <div className={`relative ${paddingbottom ? paddingbottom : 'pb-20'} z-10 ${bg} overflow-hidden`} data-sentry-component="ContentLayout" data-sentry-source-file="contentLayout.tsx">
      <div className={'absolute inset-0 z-0 bg-[size:150%] bg-centered md:bg-[size:100%] lg:bg-[size:50%] ' + bgVector} />
      <div className={`mx-auto w-full ${explore ? explore : '2xl:max-w-4xl'}`}>
        <div className="flex items-center px-8 pt-6">{children}</div>
      </div>
    </div>;
}
export function ExploreContentLayout({
  bg,
  bgVector,
  paddingbottom,
  children,
  explore
}: React.PropsWithChildren<{
  bg: string;
  bgVector: string;
  paddingbottom?: string;
  explore?: string;
}>) {
  return <div className={`mx-auto w-full ${explore ? explore : '2xl:max-w-4xl'} px-8 pt-12`} data-sentry-component="ExploreContentLayout" data-sentry-source-file="contentLayout.tsx">
      <div className={`relative ${paddingbottom ? paddingbottom : 'pb-20'} z-10 ${bg} overflow-hidden rounded-2xl`}>
        <div className={'absolute inset-0 z-0 bg-[size:150%] bg-centered md:bg-[size:100%] lg:bg-[size:50%] ' + bgVector} />
        <div className={`mx-auto w-full ${explore ? explore : '2xl:max-w-4xl'}`}>
          <div className="flex items-center px-8 pt-8">{children}</div>
        </div>
      </div>
    </div>;
}
export function NofixContentLayout({
  bg,
  bgVector,
  paddingbottom,
  maxwidth,
  children,
  border,
  href
}: React.PropsWithChildren<{
  bg: string;
  bgVector: string;
  paddingbottom?: string;
  maxwidth?: string;
  border?: string;
  href?: string;
}>) {
  const openLink = () => {
    const target = '_blank';
    if (href) {
      window.open(href, target);
    }
  };
  return <div className={`mx-auto w-full ${maxwidth} px-8 pt-16`} data-sentry-component="NofixContentLayout" data-sentry-source-file="contentLayout.tsx">
      <div onClick={openLink} className={`relative ${paddingbottom ? paddingbottom : 'pb-8'} z-10 ${bg} overflow-hidden rounded-2xl ${border} `}>
        <div className={'absolute inset-0 z-0 bg-[size:150%] bg-center md:bg-[size:100%] lg:bg-[size:95%] ' + bgVector} />
        <div className="z-10 mx-auto w-full 2xl:max-w-[1536px]">
          <div className="flex min-h-[136px] items-center p-8">{children}</div>
        </div>
      </div>
    </div>;
}
export default ContentLayout;