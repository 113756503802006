'use client';

import * as Headless from '@headlessui/react';
import React, { useState, useEffect, useRef } from 'react';
import DynamicTopRenderer from '../complete/dynamic-top-renderer';
import { NavbarItem } from '../navbar';
import { ChevronDoubleLeftIcon } from '@heroicons/react/20/solid';
import { ChevronDoubleRightIcon } from '@heroicons/react/20/solid';
import { Tooltip, TooltipContent, TooltipTrigger } from './tooltipv2';
function OpenMenuIcon() {
  return <svg data-slot="icon" viewBox="0 0 20 20" aria-hidden="true" data-sentry-element="svg" data-sentry-component="OpenMenuIcon" data-sentry-source-file="sidebar-layout-modified.tsx">
      <path d="M2 6.75C2 6.33579 2.33579 6 2.75 6H17.25C17.6642 6 18 6.33579 18 6.75C18 7.16421 17.6642 7.5 17.25 7.5H2.75C2.33579 7.5 2 7.16421 2 6.75ZM2 13.25C2 12.8358 2.33579 12.5 2.75 12.5H17.25C17.6642 12.5 18 12.8358 18 13.25C18 13.6642 17.6642 14 17.25 14H2.75C2.33579 14 2 13.6642 2 13.25Z" data-sentry-element="path" data-sentry-source-file="sidebar-layout-modified.tsx" />
    </svg>;
}
function CloseMenuIcon() {
  return <svg data-slot="icon" viewBox="0 0 20 20" aria-hidden="true" data-sentry-element="svg" data-sentry-component="CloseMenuIcon" data-sentry-source-file="sidebar-layout-modified.tsx">
      <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" data-sentry-element="path" data-sentry-source-file="sidebar-layout-modified.tsx" />
    </svg>;
}
function Collapse() {
  return <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="Collapse" data-sentry-source-file="sidebar-layout-modified.tsx">
      <path d="M3 18C2.20435 18 1.44129 17.6839 0.87868 17.1213C0.316071 16.5587 0 15.7956 0 15V3C0 2.20435 0.316071 1.44129 0.87868 0.87868C1.44129 0.316071 2.20435 0 3 0H15C15.7956 0 16.5587 0.316071 17.1213 0.87868C17.6839 1.44129 18 2.20435 18 3V15C18 15.7956 17.6839 16.5587 17.1213 17.1213C16.5587 17.6839 15.7956 18 15 18H3ZM15 2H7V16H15C15.2652 16 15.5196 15.8946 15.7071 15.7071C15.8946 15.5196 16 15.2652 16 15V3C16 2.73478 15.8946 2.48043 15.7071 2.29289C15.5196 2.10536 15.2652 2 15 2Z" fill="#888888" data-sentry-element="path" data-sentry-source-file="sidebar-layout-modified.tsx" />
    </svg>;
}
function MobileSidebar({
  open,
  close,
  children
}: React.PropsWithChildren<{
  open: boolean;
  close: () => void;
}>) {
  return <Headless.Dialog open={open} onClose={close} className="lg:hidden" data-sentry-element="unknown" data-sentry-component="MobileSidebar" data-sentry-source-file="sidebar-layout-modified.tsx">
      <Headless.DialogBackdrop transition className="fixed inset-0 bg-black/30 transition data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in" data-sentry-element="unknown" data-sentry-source-file="sidebar-layout-modified.tsx" />
      <Headless.DialogPanel transition className="fixed inset-y-0 z-30 w-full max-w-80 transition duration-300 ease-in-out data-[closed]:-translate-x-full" data-sentry-element="unknown" data-sentry-source-file="sidebar-layout-modified.tsx">
        <div className="flex h-full flex-col rounded-lg bg-white shadow-sm ring-1 ring-zinc-950/5 dark:bg-zinc-900 dark:ring-white/10">
          <div className="-mb-3 px-4 pt-3">
            <Headless.CloseButton as={NavbarItem} aria-label="Close navigation" data-sentry-element="unknown" data-sentry-source-file="sidebar-layout-modified.tsx">
              <CloseMenuIcon data-sentry-element="CloseMenuIcon" data-sentry-source-file="sidebar-layout-modified.tsx" />
            </Headless.CloseButton>
          </div>
          {children}
        </div>
      </Headless.DialogPanel>
    </Headless.Dialog>;
}
function DefualtSidebar({
  children,
  setToggleSidebar
}: React.PropsWithChildren<{
  setToggleSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}>) {
  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0
  });
  const handleMouseMove = (event: React.MouseEvent) => {
    // console.log('clientX:', event.clientX, 'clientY:', event.clientY);
    setMousePosition({
      x: 30,
      y: event.clientY
    });
  };
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === '[') {
        setToggleSidebar(true);
      }
    };

    // Add event listener
    window.addEventListener('keydown', handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [setToggleSidebar]);
  return <div className="fixed inset-y-0 left-0 top-0 z-30 w-[240px] rounded-br-xl bg-backgroundtable p-4 shadow-[0px_0px_8px_0px_#FFFFFF0D_inset] max-lg:hidden" data-sentry-component="DefualtSidebar" data-sentry-source-file="sidebar-layout-modified.tsx">
      <div className="group">
        <Tooltip placement={'left'} data-sentry-element="Tooltip" data-sentry-source-file="sidebar-layout-modified.tsx">
          <TooltipTrigger data-sentry-element="TooltipTrigger" data-sentry-source-file="sidebar-layout-modified.tsx">
            <div className="fixed inset-y-0 left-[224px] top-0 z-10 w-4 cursor-pointer rounded-br-xl border-shark-900 bg-transparent hover:border-r-4 max-lg:hidden" onMouseMove={handleMouseMove} onClick={() => setToggleSidebar(true)}></div>
          </TooltipTrigger>
          <TooltipContent style={{
          position: 'fixed',
          left: mousePosition.x,
          top: mousePosition.y,
          pointerEvents: 'none'
        }} arrowClassName="fill-shark-800" className="z-50 rounded bg-shark-800 p-2 text-white shadow-lg" data-sentry-element="TooltipContent" data-sentry-source-file="sidebar-layout-modified.tsx">
            <p className="text-sm">
              Click to collapse
              <span className="ml-3 rounded bg-shark-500 px-2.5 py-1 text-center text-sm">{`[`}</span>
            </p>
          </TooltipContent>
        </Tooltip>
        <div className="absolute -right-[56px] top-4 z-10 flex h-9 w-9 items-center justify-center rounded-lg text-transparent group-hover:border-2 group-hover:border-shark-700 group-hover:bg-shark-950 group-hover:text-shark-400" onClick={() => setToggleSidebar(true)}>
          <Tooltip placement={'right'} data-sentry-element="Tooltip" data-sentry-source-file="sidebar-layout-modified.tsx">
            <TooltipTrigger data-sentry-element="TooltipTrigger" data-sentry-source-file="sidebar-layout-modified.tsx">
              <ChevronDoubleLeftIcon className="h-5 w-5" data-sentry-element="ChevronDoubleLeftIcon" data-sentry-source-file="sidebar-layout-modified.tsx" />
            </TooltipTrigger>
            <TooltipContent style={{
            position: 'fixed',
            left: 5
          }} arrowClassName="fill-shark-800" className="z-50 rounded bg-shark-800 p-2 text-white shadow-lg" data-sentry-element="TooltipContent" data-sentry-source-file="sidebar-layout-modified.tsx">
              <p className="text-sm">
                Click to collapse
                <span className="ml-3 rounded bg-shark-500 px-2.5 py-1 text-center text-sm">{`[`}</span>
              </p>
            </TooltipContent>
          </Tooltip>
        </div>
      </div>

      {children}
    </div>;
}
function HoverSidebar({
  children,
  isVisible,
  handleMouseLeave,
  setToggleSidebar
}: React.PropsWithChildren<{
  isVisible: boolean;
  setToggleSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  handleMouseLeave: () => void;
}>) {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === '[') {
        setToggleSidebar(false);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [setToggleSidebar]);
  return <div className={`fixed bottom-2 left-2 top-[68px] z-30 w-full max-w-[240px] transition duration-300 ease-in-out data-[closed]:-translate-x-full max-lg:hidden ${isVisible ? 'translate-x-0' : '-translate-x-[105%]'}`} onMouseLeave={handleMouseLeave} data-sentry-component="HoverSidebar" data-sentry-source-file="sidebar-layout-modified.tsx">
      <div className="relative flex h-full flex-col rounded-lg bg-white p-4 shadow-sm ring-1 ring-zinc-950/5 dark:bg-zinc-900 dark:ring-white/10">
        {children}
      </div>
    </div>;
}
export function SidebarLayout({
  sidebar,
  children,
  width,
  toggleSidebar,
  setToggleSidebar
}: React.PropsWithChildren<{
  sidebar: React.ReactNode;
  width: number;
  toggleSidebar: boolean;
  setToggleSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}>) {
  let [showSidebar, setShowSidebar] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const handleMouseLeave = () => {
    setIsVisible(false);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return <div className="flex h-full min-h-screen bg-black" data-sentry-component="SidebarLayout" data-sentry-source-file="sidebar-layout-modified.tsx">
      {/* toggleSidebar feature */}
      {toggleSidebar ?
    //*Sidebar on hover
    <HoverSidebar isVisible={isVisible} handleMouseLeave={handleMouseLeave} setToggleSidebar={setToggleSidebar}>
          {sidebar}
        </HoverSidebar> :
    // Sidebar on desktop
    <DefualtSidebar setToggleSidebar={setToggleSidebar}>
          {sidebar}
        </DefualtSidebar>}

      {/* Sidebar on mobile */}
      <MobileSidebar open={showSidebar} close={() => setShowSidebar(false)} data-sentry-element="MobileSidebar" data-sentry-source-file="sidebar-layout-modified.tsx">
        {sidebar}
      </MobileSidebar>

      {/* Content */}
      <div className="flex h-full min-h-screen flex-1 flex-col bg-black lg:ml-[240px]" style={{
      marginLeft: isMobile || toggleSidebar ? '0px' : `${width}px`,
      transition: 'margin-left 0.3s ease-out'
    }}>
        <DynamicTopRenderer toggleSidebar={toggleSidebar} setToggleSidebar={setToggleSidebar} OpenMenuIcon={<ChevronDoubleRightIcon />} setShowSidebar={setShowSidebar} data-sentry-element="DynamicTopRenderer" data-sentry-source-file="sidebar-layout-modified.tsx" />
        <main className="flex-1 rounded-lg bg-black ring-1 ring-shark-950/5">
          {children}
        </main>
      </div>

      {toggleSidebar && <div className="absolute left-0 top-0 z-40 h-full w-[20px]" onMouseEnter={() => setIsVisible(true)}></div>}
    </div>;
}