import Image from 'next/image';
export default function Loading() {
  return <div className="flex h-screen items-center justify-center" data-sentry-component="Loading" data-sentry-source-file="loading.tsx">
      <div className="mx-auto h-32 w-32 select-none justify-items-center">
        <Image src="/KRNL.gif" alt="Loading..." width={200} height={200} unoptimized className="select-none rounded-lg shadow-lg" data-sentry-element="Image" data-sentry-source-file="loading.tsx" />
        <Image src="/Progress bar.gif" alt="Loading..." width={200} height={200} unoptimized className="select-none rounded-lg shadow-lg" data-sentry-element="Image" data-sentry-source-file="loading.tsx" />
      </div>
    </div>;
}
export function BookMarkLoading() {
  return <div className="flex min-h-[288px] items-center justify-center" data-sentry-component="BookMarkLoading" data-sentry-source-file="loading.tsx">
      <div className="mx-auto h-24 w-24 select-none justify-items-center">
        <Image src="/KRNL.gif" alt="Loading..." width={200} height={200} unoptimized className="select-none rounded-lg shadow-lg" data-sentry-element="Image" data-sentry-source-file="loading.tsx" />
        <Image src="/Progress bar.gif" alt="Loading..." width={200} height={200} unoptimized className="select-none rounded-lg shadow-lg" data-sentry-element="Image" data-sentry-source-file="loading.tsx" />
      </div>
    </div>;
}