import { Description, Dialog, DialogPanel, DialogTitle, Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { BookmarkIcon } from '@heroicons/react/24/outline';
import { BookkernelIDs } from '@/components/custom/bookmark';
import BookHeaderKernelIDs from './bookKernelIDs';
import BookHeaderContractIDs from './bookContractIDs';
export default function BookHeader({
  isOpen,
  setIsOpen
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) {
  return <div className="relative z-50" data-sentry-component="BookHeader" data-sentry-source-file="bookHeader.tsx">
      <BookmarkIcon className="h-5 w-5 cursor-pointer fill-white text-white" onClick={() => setIsOpen(true)} data-sentry-element="BookmarkIcon" data-sentry-source-file="bookHeader.tsx" />
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="fixed right-4 top-16 z-50 focus:outline-none" data-sentry-element="Dialog" data-sentry-source-file="bookHeader.tsx">
        <DialogPanel transition className="data-[closed]:transform-[scale(95%)] max-h-[400px] w-96 rounded-xl border border-shark-700 bg-shark-950 p-6 duration-300 ease-out data-[closed]:opacity-0" data-sentry-element="DialogPanel" data-sentry-source-file="bookHeader.tsx">
          <TabGroup data-sentry-element="TabGroup" data-sentry-source-file="bookHeader.tsx">
            <TabList className="space-x-2 text-sm/5 font-semibold text-white" data-sentry-element="TabList" data-sentry-source-file="bookHeader.tsx">
              <Tab className="p-2 focus:outline-none data-[selected]:border-b-2 data-[selected]:border-white" data-sentry-element="Tab" data-sentry-source-file="bookHeader.tsx">
                Kernels
              </Tab>
              <Tab className="p-2 focus:outline-none data-[selected]:border-b-2 data-[selected]:border-white" data-sentry-element="Tab" data-sentry-source-file="bookHeader.tsx">
                Smart Contracts
              </Tab>
            </TabList>
            <TabPanels className="pt-3.5" data-sentry-element="TabPanels" data-sentry-source-file="bookHeader.tsx">
              <TabPanel className="scrollbar-hidden max-h-[288px] overflow-y-auto" data-sentry-element="TabPanel" data-sentry-source-file="bookHeader.tsx">
                <BookHeaderKernelIDs data-sentry-element="BookHeaderKernelIDs" data-sentry-source-file="bookHeader.tsx" />
              </TabPanel>
              <TabPanel className="scrollbar-hidden max-h-[288px] overflow-y-auto" data-sentry-element="TabPanel" data-sentry-source-file="bookHeader.tsx">
                <BookHeaderContractIDs data-sentry-element="BookHeaderContractIDs" data-sentry-source-file="bookHeader.tsx" />
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </DialogPanel>
      </Dialog>
    </div>;
}