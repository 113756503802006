'use client';

import React from 'react';
import QuickGuide from '@/components/complete/Home/quick-guide';
import { HomeContentLayout } from '@/components/complete/Home/home-layout';
const Platform: React.FC = () => {
  return <HomeContentLayout href="https://docs.platform.lat/" title="Learn more about the platform" data-sentry-element="HomeContentLayout" data-sentry-component="Platform" data-sentry-source-file="page.tsx">
      <QuickGuide data-sentry-element="QuickGuide" data-sentry-source-file="page.tsx" />
    </HomeContentLayout>;
};
export default Platform;