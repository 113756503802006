import { Square2StackIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/20/solid';
import { useCallback } from 'react';
import { useState, useEffect } from 'react';
import { Tooltip, TooltipContent, TooltipTrigger } from './tooltipv2';
import { Button } from '../button';
import ShareIcon from '../../icons/ShareIcon';
import { BookmarkIcon } from '@heroicons/react/24/outline';
type ToolTipProps = {
  value: string | any;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  headerCopy: boolean;
  onDelete?: () => void;
};
export function BookkernelIDs({
  value,
  placement,
  headerCopy,
  onDelete
}: ToolTipProps) {
  const [isBookmarked, setIsBookmarked] = useState(false);
  useEffect(() => {
    const storedBookmarks = localStorage.getItem('bookKernelIDs');
    if (storedBookmarks) {
      const bookmarksArray = JSON.parse(storedBookmarks).map(BigInt);
      setIsBookmarked(bookmarksArray.includes(value));
    }
  }, [value]);

  // Function to toggle bookmark status
  const toggleBookmark = () => {
    const storedBookmarks = localStorage.getItem('bookKernelIDs');
    let bookmarksArray = storedBookmarks ? JSON.parse(storedBookmarks).map(BigInt) : [];
    if (bookmarksArray.includes(value)) {
      // Remove the kernel ID from bookmarks
      bookmarksArray = bookmarksArray.filter((id: bigint) => id !== value);
      setIsBookmarked(false);
    } else {
      // Add the kernel ID to bookmarks
      bookmarksArray.push(value);
      setIsBookmarked(true);
    }

    // Save the updated bookmarks array to localStorage as strings
    localStorage.setItem('bookKernelIDs', JSON.stringify(bookmarksArray.map((id: bigint) => id.toString())));
    if (onDelete) {
      onDelete();
    }
  };
  return <>
      {headerCopy ? <Tooltip placement={placement}>
          <TooltipTrigger className="z-40 cursor-pointer">
            <Button onClick={toggleBookmark} className="group cursor-pointer rounded-lg p-1" style={{
          background: 'rgba(255, 255, 255, 0.10)',
          backdropFilter: 'blur(4px)',
          borderRadius: '8px'
        }}>
              {isBookmarked ? <BookmarkIcon className="h-4 w-4 fill-white !text-white" /> : <BookmarkIcon className="-mx-0.5 my-1 h-4 w-4 text-shark-300 group-hover:text-white" />}
            </Button>
          </TooltipTrigger>
          <TooltipContent className="z-50 max-w-[calc(100vw-10px)] rounded-md bg-shark-800 px-2 py-1 text-white" arrowClassName="fill-shark-800">
            {isBookmarked ? 'Remove' : 'Save'}
          </TooltipContent>
        </Tooltip> : isBookmarked ? <BookmarkIcon className="h-4 w-4 fill-white !text-white" onClick={toggleBookmark} /> : <BookmarkIcon className="-mx-0.5 my-1 h-4 w-4 text-shark-300 group-hover:text-white" onClick={toggleBookmark} />}
    </>;
}
{
  /* <Tooltip placement={placement}>
  <TooltipTrigger className="z-40 cursor-pointer">
  {isBookmarked ? (
    <BookmarkIcon
      className="h-4 w-4 fill-white !text-white"
      onClick={toggleBookmark}
    />
  ) : (
    <BookmarkIcon
      className="-mx-0.5 my-1 h-4 w-4 text-shark-300 group-hover:text-white"
      onClick={toggleBookmark}
    />
  )}
  </TooltipTrigger>
  <TooltipContent
  className="z-50 max-w-[calc(100vw-10px)] rounded-md bg-shark-800 px-2 py-1 text-white"
  arrowClassName="fill-shark-800"
  >
  {isBookmarked ? 'Remove' : 'Save'}
  </TooltipContent>
  </Tooltip> */
}
export function BookContractIDs({
  value,
  placement,
  headerCopy,
  onDelete
}: ToolTipProps) {
  const [isBookmarked, setIsBookmarked] = useState(false);
  useEffect(() => {
    const storedBookmarks = localStorage.getItem('bookContractIDs');
    if (storedBookmarks) {
      const bookmarksArray = JSON.parse(storedBookmarks);
      setIsBookmarked(bookmarksArray.includes(value));
    }
  }, [value]);

  // Function to toggle bookmark status
  const toggleBookmark = () => {
    const storedBookmarks = localStorage.getItem('bookContractIDs');
    let bookmarksArray = storedBookmarks ? JSON.parse(storedBookmarks) : [];
    if (bookmarksArray.includes(value)) {
      // Remove the kernel ID from bookmarks
      bookmarksArray = bookmarksArray.filter((id: string) => id !== value);
      setIsBookmarked(false);
    } else {
      // Add the kernel ID to bookmarks
      bookmarksArray.push(value);
      setIsBookmarked(true);
    }

    // Save the updated bookmarks array to localStorage as strings
    localStorage.setItem('bookContractIDs', JSON.stringify(bookmarksArray));
    if (onDelete) {
      onDelete();
    }
  };
  return <>
      {headerCopy ? <Tooltip placement={placement}>
          <TooltipTrigger className="z-40 cursor-pointer">
            <Button onClick={toggleBookmark} className="group cursor-pointer rounded-lg p-1" style={{
          background: 'rgba(255, 255, 255, 0.10)',
          backdropFilter: 'blur(4px)',
          borderRadius: '8px'
        }}>
              {isBookmarked ? <BookmarkIcon className="h-4 w-4 fill-white !text-white" /> : <BookmarkIcon className="-mx-0.5 my-1 h-4 w-4 text-shark-300 group-hover:text-white" />}
            </Button>
          </TooltipTrigger>
          <TooltipContent className="z-50 max-w-[calc(100vw-10px)] rounded-md bg-shark-800 px-2 py-1 text-white" arrowClassName="fill-shark-800">
            {isBookmarked ? 'Remove' : 'Save'}
          </TooltipContent>
        </Tooltip> : isBookmarked ? <BookmarkIcon className="h-4 w-4 fill-white !text-white" onClick={toggleBookmark} /> : <BookmarkIcon className="-mx-0.5 my-1 h-4 w-4 text-shark-300 group-hover:text-white" onClick={toggleBookmark} />}
    </>;
}
{
  /* <Tooltip placement={placement}>
          <TooltipTrigger className="z-40 cursor-pointer">
            {isBookmarked ? (
              <BookmarkIcon
                className="h-4 w-4 fill-white !text-white"
                onClick={toggleBookmark}
              />
            ) : (
              <BookmarkIcon
                className="-mx-0.5 my-1 h-4 w-4 text-shark-300 group-hover:text-white"
                onClick={toggleBookmark}
              />
            )}
          </TooltipTrigger>
          <TooltipContent
            className="z-50 max-w-[calc(100vw-10px)] rounded-md bg-shark-800 px-2 py-1 text-white"
            arrowClassName="fill-shark-800"
          >
            {isBookmarked ? 'Remove' : 'Save'}
          </TooltipContent>
        </Tooltip> */
}